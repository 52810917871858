import React, { useState } from "react";
import {
  FaceIconStyled,
  HeaderAppBar,
  IconButtonStyled,
  LocalLibraryIconStyled,
  ArtTrackIconStyled,
  CodeIconStyled,
  WorkOutlineIconStyled,
  MenuContainer,
  IconsContainer,
  IconTextContainer,
} from "./Header.style";
import { Toolbar } from "@material-ui/core";
import { Link } from "react-router-dom";

const Header: React.FC = () => {
  const [iconText, setIconText] = useState("");
  return (
    <HeaderAppBar position={"sticky"}>
      <Toolbar>
        <MenuContainer>
          <IconsContainer>
            {/* <Link replace to={"/about"}>
              <IconButtonStyled
                onMouseEnter={() => setIconText("Me conheça")}
                onMouseLeave={() => setIconText("")}
              >
                <FaceIconStyled fontSize="large" />
              </IconButtonStyled>
            </Link> */}
            {/* <Link replace to={"/skills"}>
              <IconButtonStyled
                onMouseEnter={() => setIconText("Minhas habilidades")}
                onMouseLeave={() => setIconText("")}
              >
                <CodeIconStyled fontSize="large" />
              </IconButtonStyled>
            </Link> */}
            <Link replace to={"/work"}>
              <IconButtonStyled
                onMouseEnter={() => setIconText("Trabalhos")}
                onMouseLeave={() => setIconText("")}
              >
                <WorkOutlineIconStyled fontSize="large" />
              </IconButtonStyled>
            </Link>
            <Link replace to={"/studies"}>
              <IconButtonStyled
                onMouseEnter={() => setIconText("Estudos")}
                onMouseLeave={() => setIconText("")}
              >
                <LocalLibraryIconStyled fontSize="large" />
              </IconButtonStyled>
            </Link>
            <Link replace to={"/blog"}>
              <IconButtonStyled
                onMouseEnter={() => setIconText("Artigos")}
                onMouseLeave={() => setIconText("")}
              >
                <ArtTrackIconStyled fontSize="large" />
              </IconButtonStyled>
            </Link>
          </IconsContainer>
          <IconTextContainer>{iconText}</IconTextContainer>
        </MenuContainer>
      </Toolbar>
    </HeaderAppBar>
  );
};

export default Header;
