import styled from "styled-components";
import { Typography } from "@material-ui/core";

export const TypographyStyled = styled(Typography)`
color: #1a4c65;
font-weight: bold;
font-size: 30px;
display: flex;
flex-direction: row;
margin-bottom: 32px;
`;