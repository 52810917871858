import styled from "styled-components";
import { AppBar } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import FaceIcon from "@material-ui/icons/Face";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
import ArtTrackIcon from "@material-ui/icons/ArtTrack";
import CodeIcon from "@material-ui/icons/Code";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";

export const HeaderAppBar = styled(AppBar)`
  background-color: #3c6c83;
  box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.05);
  position: sticky;
  height: 7%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const MenuContainer = styled("div")`
display: flex;
flex-direction: column;
`;

export const IconsContainer = styled("div")`
display: flex;
flex-direction: row;
`;

export const IconTextContainer = styled("div")`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding-bottom: 10px;
font-weight: bold;
`;

export const TypographyStyled = styled(Typography)`
  color: #eeeeee;
  font-weight: bold;
  position: relative;
  right: 10px;
`;

export const IconButtonStyled = styled(IconButton)`
  margin-right: 5px;
  margin-left: 5px;
  @media (max-width: 400px) {
    margin: 0;
  }
`;

export const FaceIconStyled = styled(FaceIcon)`
  color: #eeeeee;

  @media (max-width: 400px) {
    font-size: 26px;
  }
`;

export const LocalLibraryIconStyled = styled(LocalLibraryIcon)`
  color: #eeeeee;

  @media (max-width: 400px) {
    font-size: 26px;
  }
`;

export const ArtTrackIconStyled = styled(ArtTrackIcon)`
  color: #eeeeee;

  @media (max-width: 400px) {
    font-size: 26px;
  }
`;

export const CodeIconStyled = styled(CodeIcon)`
  color: #eeeeee;

  @media (max-width: 400px) {
    font-size: 26px;
  }
`;

export const WorkOutlineIconStyled = styled(WorkOutlineIcon)`
  color: #eeeeee;

  @media (max-width: 400px) {
    font-size: 26px;
  }
`;
