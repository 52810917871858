import React from "react";
import Page from "../Page/Page";
import { TypographyStyled } from "./Message.style";

const Message: React.FC = ({ children }) => {
  return (
    <Page>
      <TypographyStyled>{children}</TypographyStyled>
    </Page>
  );
};

export default Message;
