import React from "react";
import { Route } from "react-router-dom";
import ActionPage from "../components/ActionSpace/ActionSpace";
import Background from "../components/Background/Background";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import Message from "../components/Message/Message";
import { ICard, ISkill, IStudy } from "../utils/types";
import Blog from "./blog";

import Skills from "./skills";
import Study from "./study";
import Work from "./work";

const works: ICard[] = require("../data/work.json");
const posts: ICard[] = require("../data/blog.json");
const skills: ISkill[] = require("../data/stacks.json");
const studies: IStudy[] = require("../data/studies.json");

const Main: React.FC = () => {
  return (
    <Background>
        <Header />
        <ActionPage>
          <Route exact path={"/"}>
            <Message>Bem vindo!</Message>
          </Route>
          {/* <Route exact path={"/about"}>
            <Message>Logo terá mais!</Message>
          </Route> */}
          {/* <Route exact path={"/skills"}>
            <Skills title="Skills" skills={skills} />
          </Route> */}
          <Route exact path={"/work"}>
            <Work title="Produtos / Projetos" products={works} />
          </Route>
          <Route exact path={"/studies"}>
            <Study title="Educação" studies={studies} />
          </Route>
          <Route exact path={"/blog"}>
            <Blog title="Artigos" posts={posts} />
          </Route>
        </ActionPage>
        <Footer />
    </Background>
  );
};

export default Main;
