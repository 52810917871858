import {
  FooterContainer,
  FooterIcons,
  FooterStyled,
  GitHubIconStyled,
  IconButtonStyled,
  IconStyled,
  LinkedInIconStyled,
} from "./Footer.style";

import SvgIcon from "@material-ui/core/SvgIcon";

const Footer = () => {
  return (
    <FooterStyled>
      <FooterContainer>© 2021 - Victor Hugo Gadioli</FooterContainer>
      <FooterIcons>
        <a
          href="https://github.com/victorhgadioli"
          target="_blank"
          rel="noreferrer"
        >
          <IconButtonStyled>
            <GitHubIconStyled fontSize="large" />
          </IconButtonStyled>
        </a>
        <a
          href="https://www.linkedin.com/in/victor-gadioli/"
          target="_blank"
          rel="noreferrer"
        >
          <IconButtonStyled>
            <LinkedInIconStyled fontSize="large" />
          </IconButtonStyled>
        </a>
        <a
          href="https://www.goodreads.com/user/show/52684693-victor-hugo"
          target="_blank"
          rel="noreferrer"
        >
          <IconButtonStyled>
            <IconStyled src="/static/goodreads.svg" alt="Goodreads Icon" />
          </IconButtonStyled>
        </a>
      </FooterIcons>
    </FooterStyled>
  );
};

export default Footer;
