import React from "react";
import { IStudy } from "../utils/types";
import Page from "../components/Page/Page";
import PageTitle from "../components/PageTitle/PageTitle";
import MapChart from "../components/MapChart/MapChart";

type StudyData = {
  title: string;
  studies?: IStudy[];
};

const Study: React.FC<StudyData> = ({ studies, title }) => {
  return (
    <Page>
      <PageTitle>{title}</PageTitle>
      <MapChart studies={studies} />
    </Page>
  );
};

export default Study;
