import styled from "styled-components";

export const OverlayContainer = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 21px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
`;

export const Description = styled('span')`
  color: white;
  font-size: 15px;
  letter-spacing: -0.5px;
  line-height: 1.35;
`;

export const Separator = styled('div')`
  width: 30%;
  height: 1px;
  background-color: white;
  margin-top: 0.8rem;
`;

export const IconList = styled('ul')`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

export const Link = styled('li')`
  color: white;
  width: fit-content;
  padding: 0 0.3rem;
  border: 2.5px solid rgba(255, 255, 255, 0.9);
  margin-top: 10px;

  &:hover {
    background-color: white;
    color: black;
    border-color: dodgerblue;
  }
`;

type IconProps = {
  className?: string;
  icon: string;
  onClick?: () => void;
};

export const Icon = styled.i.attrs(({
  className = '',
  icon,
}: IconProps) => ({
    className: `${icon} ${className}`,
}))<IconProps>`
  cursor: pointer;
`;

export const LinkIcon = styled(Icon)`
  font-size: 16px;
`;

export const LinkText = styled('span')`
  margin-left: 0.5rem;
  font-size: 15px;
  font-weight: 700;
`;
