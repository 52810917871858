import * as React from "react";
import styled from "styled-components";

import { ICard } from "../utils/types";
import Card from "../components/Card/Card";
import Page from "../components/Page/Page";
import PageTitle from "../components/PageTitle/PageTitle";

type WorkPageProps = {
  title: string;
  products: ICard[];
};

export const Work: React.FC<WorkPageProps> = ({ title, products }) => {
  const orderedProducts = products.sort((a, b) => {
    let dateA = a.date.substring(0, 7).split(".");
    let dateB = b.date.substring(0, 7).split(".");

    if (dateA[0] > dateB[0]) {
      return 1;
    } else if (dateA[0] < dateB[0]) {
      return -1;
    } else {
      if (dateA[1] > dateB[1]) {
        return 1;
      } else if (dateA[1] < dateB[1]) {
        return -1;
      } else {
        return 0;
      }
    }
  });

  return (
    <Page>
      <PageTitle>{title}</PageTitle>
      <CardList>
        {orderedProducts.map((product, idx) => {
          const {
            category,
            title,
            parts,
            place,
            date,
            image,
            description,
            links,
          } = product;
          return (
            <Card
              key={`product-${idx}`}
              category={category}
              title={title}
              parts={parts}
              place={place}
              date={date}
              image={image}
              description={description}
              links={links}
            />
          );
        })}
      </CardList>
    </Page>
  );
};

export default Work;

const CardList = styled("div")`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
`;
