import * as React from "react";
import openNewWindow from "../../utils/openNewWindow";

import { ILink } from "../../utils/types";
import {
  Description,
  IconList,
  Link,
  LinkIcon,
  LinkText,
  OverlayContainer,
  Separator,
} from "./OverlayCard.style";

type OverlayCardProps = {
  hide?: boolean;
  description?: string;
  links: ILink[];
};

const OverlayCard: React.FC<OverlayCardProps> = ({
  hide,
  description = "",
  links = [],
}) => {
  if (hide) {
    return null;
  }

  const onClickLink = (
    event: React.MouseEvent<HTMLLIElement>,
    href: string
  ) => {
    event.stopPropagation();
    openNewWindow(href);
  };

  return (
    <OverlayContainer>
      <Description>
        {description || ""}
        <Separator />
      </Description>
      <IconList>
        {links.map(({ icon, text, href }, idx) => (
          <Link
            key={`link-${idx}`}
            onClick={(event: React.MouseEvent<HTMLLIElement>) =>
              onClickLink(event, href)
            }
          >
            <LinkIcon icon={icon} />
            <LinkText>{text}</LinkText>
          </Link>
        ))}
      </IconList>
    </OverlayContainer>
  );
};

export default OverlayCard;
