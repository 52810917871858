import Paper from "@material-ui/core/Paper";
import styled, { css } from "styled-components";

export const CardContainer = styled(Paper)`
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 350px;
  margin-bottom: 32px;
  margin-left: 16px;
  margin-right: 16px;
  cursor: pointer;
  height: 400px;
  border-radius: 21px;

  @media (max-width: 950px) {
    padding: 0;
    min-width: 300px;
    max-width: 350px;
  }

  @media (max-width: 400px) {
    padding: 0;
    min-width: 200px;
    max-width: 300px;
    height: 300px;
  }
`;

type CardImageProps = {
  src: string;
};

export const CardImage = styled.div<CardImageProps>`
  height: 100%;
  min-width: 100%;
  background-size: cover;
  background-position: center;
  position: relative;
  border-radius: 20px;

  ${({ src }) =>
    src &&
    css`
      background-image: url(${src});
    `}

  @media (max-width: 400px) {
    height: 180px;
  }
`;

export const CardInfo = styled("div")`
  margin-top: 0.5rem;
  padding-left: 30px;
`;

export const CardCategory = styled("span")`
  font-weight: 700;
  font-size: 90%;
  width: 100%;
  display: block;
  color: dodgerblue;
  font-size: 12px;
  margin-bottom: 0.25rem;
`;

export const CardPartList = styled("div")`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 1rem;
  left: 1rem;
`;

export const CardPart = styled("span")`
  font-weight: 800;
  background-color: white;
  display: inline-block;
  width: fit-content;
  margin-bottom: 0.5rem;
  font-size: 16px;
  padding: 1px 5px;
  border-radius: 4px;
  box-shadow: 1px 1px 30px 3px rgba(0, 0, 0, 0.15);
`;

export const CardTitle = styled("span")`
  font-weight: 700;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  overflow: hidden;
  width: 100%;
  letter-spacing: -0.5px;
  font-size: 20px;
  line-height: 0.9;
`;

export const CardMeta = styled("div")`
  display: flex;
  flex-direction: column;
  margin-top: 0.25rem;
`;

export const Label = styled("span")`
  font-size: 12px;
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 500;
  background-color: #dbedff;
  width: fit-content;
  color: rgba(0, 0, 0, 0.5);
`;

export const CardPlace = styled(Label)``;

export const CardDate = styled(Label)``;
