import styled from "styled-components";
import { Paper } from "@material-ui/core";

export const PageStyled = styled(Paper)`
  background-color: white;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding: 5px;
`;
