import React, { useState } from "react";

import { ICard } from "../../utils/types";
import OverlayCard from "../OverlayCard/OverlayCard";

import {
  CardCategory,
  CardContainer,
  CardDate,
  CardImage,
  CardInfo,
  CardMeta,
  CardPart,
  CardPartList,
  CardPlace,
  CardTitle,
} from "./Card.style";

interface IFullCard extends ICard {
  className?: string;
  noClick?: boolean;
}

const Card: React.FC<IFullCard> = ({
  className = "",
  category = "",
  title,
  parts = [""],
  place = "",
  date = "",
  image,
  description = "",
  links = [],
  noClick = false,
}) => {
  const [overlay, setOverlay] = useState<boolean>(false);

  const onClickCard = () => setOverlay(!overlay);

  return (
    <CardContainer
      elevation={6}
      className={className}
      onClick={noClick ? undefined : onClickCard}
    >
      <CardImage src={image} className="image">
        <CardPartList>
          {parts.map((part, idx) => (
            <CardPart key={`part-${idx}`}>{part}</CardPart>
          ))}
        </CardPartList>
        <OverlayCard hide={!overlay} description={description} links={links} />
      </CardImage>
      <CardInfo>
        <CardCategory className="category">{category}</CardCategory>
        <CardTitle className="title">{title}</CardTitle>
        <CardMeta>
          <CardPlace className="place">{place}</CardPlace>
          <CardDate className="date">{date}</CardDate>
        </CardMeta>
      </CardInfo>
    </CardContainer>
  );
};

export default Card;
