import styled from "styled-components";

export const BackgroundStyled = styled("div")`
  background-color: #91b1be;

  width: 100%;
  height: 100%;
  min-height: 100vh;

  display: flex;
  flex-flow: column;
  justify-content: center;
`;
