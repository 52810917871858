import styled from "styled-components";
import { Paper } from "@material-ui/core";

export const MapSpace = styled(Paper)`
  background-color: #fff;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 50vw;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin: 0 0 40px 0;
  border-radius: 400px;
`;

export const Container = styled("div")`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

export const InfoSpace = styled("div")`
  background-color: #fff;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
