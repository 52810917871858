import styled from "styled-components";
import { Container, IconButton } from "@material-ui/core";
import GitHubIcon from "@material-ui/icons/GitHub";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

export const IconStyled = styled("img")`
  width: 32px;
  height: 32px;
`;

export const FooterStyled = styled("footer")`
  background-color: #1a4c65;
  display: flex;
  flex-direction: column;
  color: #eeeeee;
  padding: 20px 0;
`;

export const FooterContainer = styled(Container)`
  display: flex;
  justify-content: center;
  gap: 16px;
  flex-wrap: wrap;
`;

export const FooterIcons = styled("div")`
  display: flex;
  justify-content: center;
`;

export const IconButtonStyled = styled(IconButton)`
  margin-right: 5px;
  margin-left: 5px;
  @media (max-width: 400px) {
    margin: 0;
  }
`;

export const GitHubIconStyled = styled(GitHubIcon)`
  color: #eeeeee;

  @media (max-width: 400px) {
    font-size: 26px;
  }
`;

export const LinkedInIconStyled = styled(LinkedInIcon)`
  color: #eeeeee;

  @media (max-width: 400px) {
    font-size: 26px;
  }
`;
