import React, { memo, useState } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";
import { MapSpace, InfoSpace, Container } from "./MapChart.style";
import useWindowSize from "../../utils/useWindowSize";
import geoUrl from "../../data/map.json";
import { IMarker, IStudy } from "../../utils/types";
import Card from "../Card/Card";

interface IMap {
  studies: IStudy[] | undefined;
}

const MapChart: React.FC<IMap> = ({ studies }) => {
  const { width = 0 } = useWindowSize();
  const [selected, setSelected] = useState("");

  if (!studies) {
    return <></>;
  }

  const markers: IMarker[] = studies
    .map((study) => {
      return study.marker;
    });

  const orderedStudies = studies.sort((a, b) => {
    let dateA = a.date.substring(0, 7).split(".");
    let dateB = b.date.substring(0, 7).split(".");

    if (dateA[0] > dateB[0]) {
      return 1;
    } else if (dateA[0] < dateB[0]) {
      return -1;
    } else {
      if (dateA[1] > dateB[1]) {
        return 1;
      } else if (dateA[1] < dateB[1]) {
        return -1;
      } else {
        return 0;
      }
    }
  });

  return (
    <Container>
      {width > 700 ? (
        <MapSpace elevation={6}>
          <ComposableMap
            data-tip=""
            height={width / 4}
            width={width / 2}
            projectionConfig={{ scale: width / 12 }}
          >
            <Geographies geography={geoUrl}>
              {({ geographies }) =>
                geographies.map((geo) => (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    style={{
                      default: {
                        fill: "#D6D6DA",
                        outline: "none",
                      },
                      hover: {
                        fill: "#D6D6DA",
                        outline: "none",
                      },
                      pressed: {
                        fill: "#D6D6DA",
                        outline: "none",
                      },
                    }}
                  />
                ))
              }
            </Geographies>
            {markers.map(({ name, coordinates, markerOffset }) => (
              <Marker
                key={name}
                coordinates={coordinates}
                onMouseEnter={() => {
                  setSelected(name);
                }}
              >
                {selected === name ? (
                  <circle r={6} fill="#fff" stroke="#1A4c65" strokeWidth={3} />
                ) : (
                  <circle r={4} fill="#1A4c65" stroke="#fff" strokeWidth={2} />
                )}
                <text
                  textAnchor="middle"
                  y={markerOffset}
                  style={{
                    fontFamily: "system-ui",
                    fill: "#5D5A6D",
                    fontSize: 12,
                  }}
                >
                  {name}
                </text>
              </Marker>
            ))}
          </ComposableMap>
        </MapSpace>
      ) : (
        <></>
      )}

      

      <InfoSpace>
        {orderedStudies.map((product, idx) => {
          const {
            category,
            title,
            parts,
            place,
            date,
            image,
            description,
            links,
          } = product;
          const city = place.split(",")[0];
          if (selected === "" || city === selected) {
            return (
              <Card
                key={`product-${idx}`}
                category={category}
                title={title}
                parts={parts}
                place={place}
                date={date}
                image={image}
                description={description}
                links={links}
              />
            );
          } else {
            return <></>;
          }
        })}
      </InfoSpace>
    </Container>
  );
};

export default memo(MapChart);
